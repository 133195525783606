@tailwind base;

@import 'swiper/swiper-bundle.min.css';
@import "bootstrap/dist/css/bootstrap.min.css";
@import "atropos/atropos.css";
@import "react-18-image-lightbox/style.css";

@import "./variable";

@import "./core/reset";
@import "./core/classes";
@import "./core/typography";

@import "./components/grid";
@import "./components/countdown";
@import "./components/customiconwithtext";
@import "./components/dropcaps";
@import "./components/fancytext";
@import "./components/imagegallery";
@import "./components/infobanner";
@import "./components/lists";
@import "./components/overlineiconbox";
@import "./components/portfolio";
@import "./components/textslider";