@import 'swiper/swiper-bundle.min.css';
@import "bootstrap/dist/css/bootstrap.min.css";
@import "atropos/atropos.css";
@import "react-18-image-lightbox/style.css";
@tailwind base;
/* Transition */
:root {
  --default-transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  --alt-font: "poppins", sans-serif;
  --primary-font: "Roboto", sans-serif;
  --viewport-height: 100vh;
  --xl: 1600px;
  --lg: 1199px;
  --md: 991px;
  --sm: 767px;
  --xs: 575px;
  --xxs: 480px;
}

.collapse {
  visibility: visible !important;
}

::selection {
  color: #000;
  background: #dbdbdb;
}

::-moz-selection {
  color: #a6a6a6;
  background: #dbdbdb;
}

html {
  @apply text-[14px] lg:text-[13px] md:text-[12px] sm:text-[11px];
  scroll-behavior: inherit !important;
}

body {
  @apply font-sans text-base font-normal;
  color: #828282;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
}

a {
  transition: var(--default-transition);
}

button:focus,
.btn-close:focus,
.accordion-button:focus {
  box-shadow: none;
  outline: none;
}

.container,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 1199px) {
  .container,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xs {
    max-width: 1170px;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}
.row > * {
  padding-left: 15px;
  padding-right: 15px;
}
.row.gx-0, .row.g-0 {
  margin-left: 0;
  margin-right: 0;
}
.row.gx-0 > *, .row.g-0 > * {
  padding-left: 0;
  padding-right: 0;
}

.cover-background {
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  background-position: center center;
}

iframe {
  width: 100%;
}

.error iframe {
  border: 1px solid red;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:active,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover {
  transition: background-color 5000s;
  transition: all 0s 50000s;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
  -webkit-text-fill-color: #828282 !important;
  color: #828282 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

input,
textarea {
  line-height: 1;
}
input:focus,
textarea:focus {
  outline: none;
}
input.errors-danger,
textarea.errors-danger {
  @apply border-error;
}
input.errors-danger[type=checkbox],
textarea.errors-danger[type=checkbox] {
  box-shadow: 0 0 0 1px rgb(255, 0, 0), 1px 1px 0 0 rgb(255, 0, 0);
}
input:-webkit-autofill,
input -webkit-autofill:hover,
input -webkit-autofill:focus,
input -webkit-autofill:active,
textarea:-webkit-autofill,
textarea -webkit-autofill:hover,
textarea -webkit-autofill:focus,
textarea -webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.checkbox-with-label {
  @apply inline-block;
}
.checkbox-with-label [type=checkbox] {
  @apply absolute opacity-0 top-0 left-0;
}
.checkbox-with-label [type=checkbox]:checked + span::after {
  @apply absolute top-0 left-0 h-full w-full text-center text-xs leading-[15px] font-black;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
}

.fit-video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.fit-video iframe,
.fit-video video {
  @apply w-full h-full absolute top-0 left-0;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModalPortal {
  @apply fixed;
  z-index: 1042;
}
.ReactModalPortal .ril__outer {
  background-color: rgba(35, 35, 35, 0.9);
}
.ReactModalPortal .ril__inner {
  padding: 60px 0;
}
@media (orientation: landscape) {
  .ReactModalPortal .ril__inner .ril__image {
    max-height: 100%;
  }
  @screen md {
    .ReactModalPortal .ril__inner .ril__image {
      max-height: calc(100% + 300px);
    }
  }
}
@media (orientation: portrait) {
  @screen md {
    .ReactModalPortal .ril__inner .ril__image {
      max-height: inherit;
    }
  }
}
.ReactModalPortal .ril__toolbar {
  background-color: transparent;
}
.ReactModalPortal .ril__toolbar .ril__closeButton {
  margin: 30px 0 30px 0;
  background: none;
  font-family: "feather" !important;
  opacity: 1;
}
.ReactModalPortal .ril__toolbar .ril__closeButton:before {
  content: "\ea02";
  color: #fff;
  font-size: 25px;
}
.ReactModalPortal .ril__toolbar .ril__zoomInButton,
.ReactModalPortal .ril__toolbar .ril__zoomOutButton {
  @apply hidden;
}
.ReactModalPortal .ril__navButtonPrev,
.ReactModalPortal .ril__navButtonNext {
  background: none;
  background-color: #232323;
  font-family: "feather" !important;
  margin: 0;
  padding: 0;
  opacity: 0.7;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
@screen sm {
  .ReactModalPortal .ril__navButtonPrev,
  .ReactModalPortal .ril__navButtonNext {
    width: 40px;
    height: 40px;
    opacity: 1;
  }
}
.ReactModalPortal .ril__navButtonPrev:before,
.ReactModalPortal .ril__navButtonNext:before {
  @apply flex justify-center text-white;
  opacity: 0.7;
  font-size: 22px;
}
@screen sm {
  .ReactModalPortal .ril__navButtonPrev:before,
  .ReactModalPortal .ril__navButtonNext:before {
    font-size: 18px;
  }
}
.ReactModalPortal .ril__navButtonPrev {
  left: 40px;
}
.ReactModalPortal .ril__navButtonPrev:before {
  content: "\e910";
}
.ReactModalPortal .ril__navButtonPrev:before:hover {
  color: #fff;
}
.ReactModalPortal .ril__navButtonNext {
  right: 40px;
}
.ReactModalPortal .ril__navButtonNext:before {
  content: "\e912";
}
.ReactModalPortal .ril__captionContent {
  @apply text-xs;
  color: #f3f3f3;
}

.up-down-ani {
  @apply relative mx-auto;
  animation-name: down-arrow;
  animation-duration: 0.8s;
  animation-timing-function: linear;
  animation-delay: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: var(--default-transition);
}

.swiper-slide {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.swiper-auto-slide .swiper-slide {
  width: auto;
}

.white-move .swiper-slide,
.white-move .swiper-wrapper {
  cursor: url(../img/icon-move-light.png), move;
}

.black-move .swiper-slide,
.black-move .swiper-wrapper {
  cursor: url(../img/icon-move-black.png), move;
}

.vertical-white-move .swiper-slide,
.vertical-white-move .swiper-wrapper {
  cursor: url(../img/vertical-icon-move-light.png), move;
}

.vertical-black-move .swiper-slide,
.vertical-black-move .swiper-wrapper {
  cursor: url(../img/vertical-icon-move-black.png), move;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 45px;
  @apply xs:bottom-[30px];
}

.swiper-pagination-light {
  --swiper-pagination-color: #ffffff;
}

.swiper-pagination-dark {
  --swiper-pagination-color: #232323;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  @apply bg-transparent align-middle rounded-full inline-block h-3 my-auto mx-[7px] w-3 border-2 border-solid outline-none;
  border-color: var(--swiper-pagination-color);
  width: var(--swiper-pagination-width);
  height: var(--swiper-pagination-height);
  transition: var(--default-transition);
  --swiper-pagination-bullet-horizontal-gap: 6px;
}
.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--swiper-pagination-color);
}

.swiper-pagination-fractional .swiper-pagination-bullet {
  @apply rounded-none bg-transparent border-none text-lg text-darkgray font-serif font-medium;
  width: 25px;
  height: 20px;
  margin-right: 20px !important;
  text-align: center;
  color: #232323;
  opacity: 0.5;
  transition: var(--default-transition);
}
.swiper-pagination-fractional .swiper-pagination-bullet:hover {
  opacity: 1;
}
.swiper-pagination-fractional .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply rounded-none bg-transparent border-none underline underline-offset-4 font-serif opacity-100;
}

.swiper-pagination-vertical.swiper-pagination-bullets {
  right: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Swiper pagination medium */
.swiper-pagination-medium {
  --swiper-pagination-width: 10px;
  --swiper-pagination-height: 10px;
  --swiper-pagination-active-width: 16px;
  --swiper-pagination-active-height: 16px;
}

.swiper-pagination-large {
  --swiper-pagination-width: 12px;
  --swiper-pagination-height: 12px;
  --swiper-pagination-active-width: 16px;
  --swiper-pagination-active-height: 16px;
}

.swiper-pagination-big .swiper-pagination-bullet {
  --swiper-pagination-width: 14px;
  --swiper-pagination-height: 14px;
}

/* Swiper Pagination Style 01 */
.swiper-pagination-01 .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 10px 5px;
  @apply opacity-100;
  background-color: var(--swiper-pagination-color);
  border-color: var(--swiper-pagination-color);
}
.swiper-pagination-01 .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-transparent w-[16px] h-[16px];
}

/* Swiper Pagination Style 02 */
.swiper-pagination-02 .swiper-pagination-bullets .swiper-pagination-bullet {
  @apply opacity-30;
  background-color: var(--swiper-pagination-color);
  border-color: var(--swiper-pagination-color);
}
.swiper-pagination-02 .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply opacity-100;
}

.swiper-button-next,
.swiper-button-prev {
  margin-top: 0;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: "Feather";
  font-size: 15px;
}

.swiper-button-prev {
  left: 0;
}
.swiper-button-prev:after {
  content: "\e910";
}

.swiper-button-next {
  right: 0;
}
.swiper-button-next:after {
  content: "\e912";
}

.swiper-navigation-light .swiper-button-next,
.swiper-navigation-light .swiper-button-prev {
  --swiper-navigation-background: #fff;
  --swiper-navigation-color: #232323;
}

.swiper-navigation-dark .swiper-button-next,
.swiper-navigation-dark .swiper-button-prev {
  --swiper-navigation-background: #232323;
  --swiper-navigation-color: #fff;
}

/* slider navigation style 01 */
.swiper-navigation-01 .swiper-button-next,
.swiper-navigation-01 .swiper-button-prev {
  background-color: var(--swiper-navigation-background);
  color: var(--swiper-navigation-color);
  transition: var(--default-transition);
  @apply flex justify-center items-center w-[40px] h-[40px] leading-[40px];
}
.swiper-navigation-01 .swiper-button-next:hover,
.swiper-navigation-01 .swiper-button-prev:hover {
  background-color: var(--swiper-navigation-color);
  color: var(--swiper-navigation-background);
}

/* slider navigation style 02 */
.swiper-navigation-02 .swiper-button-next,
.swiper-navigation-02 .swiper-button-prev {
  background-color: transparent;
  border: 2px solid var(--swiper-navigation-background);
  color: var(--swiper-navigation-background);
  transition: var(--default-transition);
  @apply flex justify-center items-center w-[45px] h-[45px] leading-[45px] rounded-full;
}
.swiper-navigation-02 .swiper-button-next:hover,
.swiper-navigation-02 .swiper-button-prev:hover {
  background-color: var(--swiper-navigation-background);
  color: var(--swiper-navigation-color);
  border-color: transparent;
}
.swiper-navigation-02.swiper-navigation-light .swiper-button-next,
.swiper-navigation-02.swiper-navigation-light .swiper-button-prev {
  border-color: rgba(255, 255, 255, 0.3);
}

/* slider navigation style 03 */
.swiper-navigation-03 .swiper-button-next,
.swiper-navigation-03 .swiper-button-prev {
  margin-top: 0;
  background-color: transparent;
  border: 1px solid;
  color: var(--swiper-navigation-color);
  transition: var(--default-transition);
  @apply flex justify-center items-center w-[40px] h-[40px] leading-[40px] rounded-full border-mediumgray;
}
.swiper-navigation-03 .swiper-button-next:hover,
.swiper-navigation-03 .swiper-button-prev:hover {
  background-color: var(--swiper-navigation-background);
  color: var(--swiper-navigation-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-color: transparent;
}

/* slider navigation style 04 */
.swiper-navigation-04 .swiper-button-next,
.swiper-navigation-04 .swiper-button-prev {
  margin: 0;
  transform: translateY(-50%);
  background-color: var(--swiper-navigation-background);
  color: var(--swiper-navigation-color);
  transition: var(--default-transition);
  box-shadow: 0 0 35px rgba(40, 42, 54, 0.18);
  @apply flex justify-center items-center w-[40px] h-[40px] leading-[40px] rounded-full;
}
.swiper-navigation-04 .swiper-button-next:hover,
.swiper-navigation-04 .swiper-button-prev:hover {
  background-color: var(--swiper-navigation-color);
  color: var(--swiper-navigation-background);
}

.swiper-default-nav-hidden > .swiper-button-prev,
.swiper-default-nav-hidden > .swiper-button-next {
  @apply hidden;
}

@screen xl {
  .xl-nav-hidden > .swiper-button-prev,
  .xl-nav-hidden > .swiper-button-next {
    @apply hidden;
  }
}

@screen lg {
  .lg-nav-hidden > .swiper-button-prev,
  .lg-nav-hidden > .swiper-button-next {
    @apply hidden;
  }
}

@screen md {
  .md-nav-hidden > .swiper-button-prev,
  .md-nav-hidden > .swiper-button-next {
    @apply hidden;
  }
}

@screen sm {
  .sm-nav-hidden > .swiper-button-prev,
  .sm-nav-hidden > .swiper-button-next {
    @apply hidden;
  }
}

@screen xs {
  .xs-nav-hidden > .swiper-button-prev,
  .xs-nav-hidden > .swiper-button-next {
    @apply hidden;
  }
}

.content-box-image {
  height: 33.3333333333%;
}

.personalportfolio .menu-list-wrapper.menu-list-wrapper-small {
  max-height: calc(65vh - 100px);
}
.personalportfolio .menu-list-wrapper {
  overflow: auto;
  height: calc(100vh - 150px);
}
.personalportfolio .menu-list-item {
  clear: both;
  position: relative;
  list-style: none;
  padding: 13px 0;
}
.personalportfolio .menu-list-item a {
  font-size: 44px;
  line-height: 50px;
  position: relative;
  padding: 0;
  color: #fff;
  display: inline-block;
}

/* svg drawing path */
.drawing-animation path,
.drawing-animation circle,
.drawing-animation rect,
.drawing-animation polygon,
.drawing-animation ellipse {
  fill: none;
  stroke-width: 20px;
  stroke-dasharray: 20000;
  stroke-dashoffset: 20000;
  -webkit-animation: drawing-animation 6s linear forwards;
  animation: drawing-animation 6s linear forwards;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

@keyframes drawing-animation {
  to {
    stroke-dashoffset: 0;
  }
}
/* Tabs */
.switch-tabs .nav-tabs {
  border-top: 1px solid #dee2e6;
}
.switch-tabs .nav-tabs .nav-link {
  padding: 30px 70px;
  border-radius: 0;
  border: none;
  background: transparent;
  position: relative;
  font-weight: 600;
  color: gray;
}
.switch-tabs .nav-tabs .nav-link:focus {
  box-shadow: none;
  outline: none;
}
.switch-tabs .nav-tabs .nav-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 3px;
  background: var(--base-color);
  transition: var(--default-transition);
}
.switch-tabs .nav-tabs .nav-link.active {
  color: var(--base-color);
}
.switch-tabs .nav-tabs .nav-link.active:after {
  width: 100%;
}

button:focus-visible {
  box-shadow: none;
  outline: none;
}

.typed-cursor {
  @apply hidden;
}

.tag-cloud a {
  display: inline-block;
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  padding: 5px 18px 6px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  margin-right: 15px;
}
.tag-cloud a:last-child {
  margin-right: 0;
}
.tag-cloud a:hover {
  box-shadow: 0 0 10px rgba(23, 23, 23, 0.1);
  color: #828282;
}

.btn-reply {
  float: right;
  font-size: 11px;
  line-height: initial;
  padding: 7px 16px 6px;
  border-radius: 50px;
  border: 1px solid rgba(44, 44, 44, 0.25);
  background-color: #fff;
}
.btn-reply:hover {
  border-color: #0038e3;
  background: #0038e3;
  color: #fff;
}

.overlap-section {
  margin-top: var(--overlapheight);
}

.full-screen {
  height: calc(var(--viewport-height) - var(--header-height));
}

@screen xs {
  .xs\:full-screen {
    height: calc(var(--viewport-height) - var(--header-height)) !important;
  }
}

/* React Custom Scrollbar */
.smooth-scrollbar {
  height: 100%;
}
.smooth-scrollbar .scroll-content {
  padding-right: 15px;
  width: 100%;
}
.smooth-scrollbar .scrollbar-track-y {
  right: 8px;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.1490196078);
}
.smooth-scrollbar .scrollbar-thumb {
  left: -1px;
  width: 4px;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.7490196078);
}
.smooth-scrollbar.dark .scrollbar-track-y {
  background-color: rgba(0, 0, 0, 0.1490196078);
}
.smooth-scrollbar.dark .scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.7490196078);
}
.smooth-scrollbar.light .scrollbar-track-y {
  background-color: #939393;
}
.smooth-scrollbar.light .scrollbar-thumb {
  background-color: #e4e4e4;
}

/* fancy box background */
.fancy-box-background {
  position: relative;
  z-index: 1;
}

.bg-banner-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  visibility: hidden;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background-size: cover;
}

.fancy-box-background .opacity-medium {
  z-index: -1;
}

.fancy-box-background .fancy-box-item {
  position: static;
}

.fancy-box-background.fancy-box-col .fancy-box-item {
  position: relative;
}

.fancy-box-background .fancy-box-item:hover .bg-banner-image {
  opacity: 1;
  visibility: visible;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.fancy-box-background .fancy-box-item:hover .light,
.fancy-box-background .fancy-box-item:hover .light i {
  color: #fff;
}

.fancy-box-background .fancy-box-item:hover .dark {
  color: #232323;
}

.fancy-box-background:after {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
  position: absolute;
}

.fancy-box-background .fancy-text-box li:hover {
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  cursor: pointer;
}

.fancy-box-background .fancy-text-box li:hover span {
  color: rgba(255, 255, 255, 0.9);
}

.fancy-box-background .fancy-text-box li:hover a {
  color: rgba(255, 255, 255, 0.9);
}

.feature-box-main {
  background: #fff;
  transition: var(--default-transition);
}
.feature-box-main:hover {
  @apply bg-darkgray;
}
.feature-box-main:hover .feature-box-content {
  color: white;
}
.feature-box-main:hover span {
  color: white;
}
.feature-box-main:hover a {
  color: white;
}

/* Custom Modal */
.modal-wrapper .inner-modal {
  max-width: 100%;
  perspective: 600px;
}
.modal-wrapper .inner-modal > div {
  max-width: 100%;
}
.modal-wrapper .btn-close {
  position: fixed;
  top: 30px;
  right: 30px;
  font-size: 30px;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  cursor: pointer;
  color: white;
  opacity: 1;
}

/* Custom React Modal */
.ReactModalPortal.custom_modal {
  @apply opacity-0 invisible;
  transition: var(--default-transition);
}
.ReactModalPortal.custom_modal.open {
  @apply opacity-100 visible;
}
.ReactModalPortal.custom_modal.open .ReactModal__Content {
  opacity: 1;
}
.ReactModalPortal.custom_modal.open .ReactModal__Content.fadeIn .modal-inner {
  opacity: 1;
}
.ReactModalPortal.custom_modal.open .ReactModal__Content.fade-zoom .modal-inner {
  opacity: 1;
  transform: scale(1);
}
.ReactModalPortal.custom_modal.open .ReactModal__Content.rotate-zoom .modal-inner {
  opacity: 1;
  transform: rotateX(0deg);
}
.ReactModalPortal.custom_modal .ReactModal__Content {
  inset: 50% auto auto 50% !important;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 1;
}
.ReactModalPortal.custom_modal .ReactModal__Content.fadeIn .modal-inner {
  opacity: 0;
  transition: var(--default-transition);
}
.ReactModalPortal.custom_modal .ReactModal__Content.fade-zoom .modal-inner {
  opacity: 0;
  transform: scale(0.5);
  transition: var(--default-transition);
}
.ReactModalPortal.custom_modal .ReactModal__Content.rotate-zoom .modal-inner {
  opacity: 0;
  transform: rotateX(60deg) perspective(600px);
  transition: var(--default-transition);
}

.all-demo-btn-section {
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  width: 655px;
  z-index: 70;
  transform: translateX(calc(100% + 15px));
  transition: var(--default-transition);
}
.all-demo-btn-section.show {
  transform: translateX(0);
}
.all-demo-btn-section.show .all-demo {
  width: 60px;
  min-width: 60px;
  box-shadow: none;
  transform: translateX(-100%);
}
.all-demo-btn-section .all-demo {
  @apply absolute left-0 flex items-center justify-center min-w-[150px] h-[54px] py-[12px] px-[15px] shadow-[0_0_15px_rgba(0,0,0,0.15)] font-medium uppercase cursor-pointer;
  z-index: 10;
  transform: translateX(calc(-100% - 15px));
  transition: var(--default-transition);
}
.all-demo-btn-section .unique-demos .scroll-content .grid-wrapper ul li a .portfolio-switchimg .portfolio-content span {
  text-transform: capitalize;
}

.smooth-scrollbar[data-scrollbar] {
  display: flex;
  align-items: center;
}
.smooth-scrollbar[data-scrollbar].scrollbar-appear {
  display: block;
}

.text-basecolor {
  color: var(--base-color);
}

.hover\:text-basecolor:hover {
  color: var(--base-color);
}

.bg-basecolor {
  background-color: var(--base-color);
}

.bg-transparent-gradient-smoky-black {
  background: radial-gradient(circle, rgba(16, 16, 16, 0) 0, #101010 100%);
}

.border-basecolor {
  border-color: var(--base-color);
}

.bg-gradient-dark-slate-blue {
  background-image: linear-gradient(to right, #252c3e, rgba(37, 44, 62, 0.66), rgba(37, 44, 62, 0.42), rgba(37, 44, 62, 0.1), rgba(37, 44, 62, 0));
}

.after\:bg-basecolor:after {
  background-color: var(--base-color);
}

.before\:bg-basecolor:before {
  background-color: var(--base-color);
}

.btn-box-shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  letter-spacing: 1px;
  padding: 18px 38px;
}

.btn-box-shadow-flat {
  box-shadow: 2px 2px 0 #000;
  border-width: 1px;
}

.text-gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.small-input,
.small-textarea {
  padding: 13px 15px;
  font-size: 14px;
}

.small-select select {
  padding: 13px 15px;
}

.medium-input,
.medium-textarea,
.medium-select select {
  padding: 15px 20px;
  font-size: 14px;
}

.extra-medium-textarea {
  font-size: 14px;
}

.large-input {
  padding: 18px 25px 19px;
}

.extra-large-input {
  padding: 20px 36px 23px;
  font-size: 16px;
}

/* Custom */
@keyframes video-icon-sonar {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.1);
    transform: translate(-50%, -50%) scale(0.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1);
  }
}
@keyframes down-arrow {
  0% {
    opacity: 0;
    top: 0px;
  }
  18% {
    opacity: 0.2;
    top: 3px;
  }
  36% {
    opacity: 0.4;
    top: 8px;
  }
  54% {
    opacity: 0.6;
    top: 18px;
  }
  72% {
    opacity: 0.8;
    top: 18px;
  }
  100% {
    opacity: 1;
    top: 18px;
  }
}
@keyframes zoomout {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
/* text stroke */
.text-border {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
}

.text-gradient-peacock-blue-crome-yellow {
  background: linear-gradient(to right, #36c1e1, #36c1e1, #74cf8e, #bac962, #feb95b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* text stroke width */
.text-border-width-1px {
  -webkit-text-stroke-width: 1px;
}

.text-border-width-2px {
  -webkit-text-stroke-width: 2px;
}

.text-border-width-3px {
  -webkit-text-stroke-width: 3px;
}

.text-border-width-4px {
  -webkit-text-stroke-width: 4px;
}

.text-border-width-5px {
  -webkit-text-stroke-width: 5px;
}

/* text stroke fill color */
.text-stroke-fill-transparent {
  -webkit-text-fill-color: transparent;
}

/* border gradient */
.border-gradient-light-purple-light-red {
  border-image: linear-gradient(to right top, #bb85f9, #c887e5, #da8bca, #eb8fb0, #fb9398);
  border-image-slice: 1;
}

/* for safari */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .border-gradient-light-purple-light-red {
    border-image: linear-gradient(to right top, #bb85f9, #c887e5, #da8bca, #eb8fb0, #fb9398) 1/0 0 1 0;
    border-image-slice: 1;
  }
}
/* Box shadow */
.box-shadow,
.box-shadow-hover:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}

.box-shadow-double-large,
.box-shadow-double-large-hover:hover {
  box-shadow: 0 0 35px rgba(40, 42, 54, 0.18);
}

.box-shadow-large,
.box-shadow-large-hover:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
}

.box-shadow-extra-large,
.box-shadow-extra-large-hover:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
}

.box-shadow-medium,
.box-shadow-medium-hover:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}

.box-shadow-small,
.box-shadow-small-hover:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
}

/* text shadow */
.text-shadow-small {
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}

.text-shadow-medium {
  text-shadow: rgba(0, 0, 0, 0.03) 0px 10px 10px;
}

.text-shadow-large {
  text-shadow: 0 0 50px rgba(0, 0, 0, 0.9);
}

.text-shadow-extra-large {
  text-shadow: 0 0 60px rgba(0, 0, 0, 0.9);
}

.text-shadow-double-large {
  text-shadow: 0 0 100px rgba(0, 0, 0, 0.9);
}

/* text decoration underline */
.text-decoration-line-bottom,
.text-decoration-line-bottom-medium,
.text-decoration-line-bottom-thick,
.text-decoration-line-thick,
.text-decoration-line-wide,
.text-middle-line {
  position: relative;
  white-space: nowrap;
}

.text-decoration-line-wide:before {
  content: "";
  border-bottom: 1px solid;
  left: -10px;
  position: absolute;
  top: 50%;
  width: calc(100% + 20px);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.text-decoration-line-thick:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: #333333;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  z-index: -1;
}

.text-decoration-line-bottom:before {
  content: "";
  border-bottom: 1px solid;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
}

.text-decoration-line-bottom-medium:before {
  content: "";
  border-bottom: 2px solid;
  left: 0;
  position: absolute;
  top: 90%;
  width: 100%;
}

.text-decoration-line-bottom-thick:before {
  content: "";
  border-bottom: 3px solid;
  left: 0;
  position: absolute;
  top: 85%;
  width: 100%;
}

/* Image mask */
.image-mask {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

/* Image back offset shadow */
.image-back-offset-shadow {
  z-index: 1;
  @apply opacity-100 m-0 relative inline-block;
  transition: var(--default-transition);
}
.image-back-offset-shadow.active {
  @apply opacity-100;
}
.image-back-offset-shadow span {
  background-image: linear-gradient(to right top, #b783ff, #e37be0, #fa7cc1, #ff85a6, #ff9393);
  @apply absolute top-0 left-0 w-full h-full -z-[1];
}

.transition-default {
  transition: var(--default-transition);
}

/* Parallax */
@screen xl {
  .xl-no-parallax {
    transform: translateY(0) !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-position: center;
  }
}

@screen lg {
  .lg-no-parallax {
    transform: translateY(0) !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-position: center;
  }
}

@screen md {
  .md-no-parallax {
    transform: translateY(0) !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-position: center;
  }
}

@screen sm {
  .sm-no-parallax {
    transform: translateY(0) !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-position: center;
  }
}

@screen xs {
  .xs-no-parallax {
    transform: translateY(0) !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-position: center;
  }
}

@screen xxs {
  .xxs-no-parallax {
    transform: translateY(0) !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-position: center;
  }
}

/* Css Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    filter: blur(20px);
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}
.random-char-fadein span {
  display: inline-block;
  opacity: 0;
  filter: blur(20px);
  transform: translateY(100px);
  transition-property: all;
  transition-duration: 2s;
  transition-timing-function: ease-in-out;
}
.random-char-fadein span:nth-child(even).active {
  opacity: 1;
  filter: blur(0);
  transform: translateY(0);
}
.random-char-fadein span:nth-child(odd) {
  transition-delay: 0.5s;
}
.random-char-fadein span:nth-child(odd).active {
  opacity: 1;
  filter: blur(0);
  transform: translateY(0);
}

h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
  margin: 0 0 25px 0;
  padding: 0;
  letter-spacing: -0.5px;
  font-weight: inherit;
}

h1, .heading-1 {
  font-size: 5rem;
  line-height: 5rem;
}

h2, .heading-2 {
  font-size: 3.9rem;
  line-height: 4.2rem;
}

h3, .heading-3 {
  font-size: 3.4rem;
  line-height: 3.9rem;
}

h4, .heading-4 {
  font-size: 2.85rem;
  line-height: 3.4rem;
}

h5, .heading-5 {
  font-size: 2.3rem;
  line-height: 3rem;
}

h6, .heading-6 {
  font-size: 1.8rem;
  line-height: 2.5rem;
}

@screen lg {
  h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
    margin: 0 0 15px 0;
  }
}
@screen md {
  .text-xl {
    font-size: 18px;
    line-height: 26px;
  }
}

@screen xl {
  .text-xbig {
    font-size: 200px;
    line-height: 200px;
  }
}
@screen lg {
  .text-xbig {
    font-size: 170px;
    line-height: 170px;
  }
}
@screen md {
  .text-xbig {
    font-size: 130px;
    line-height: 130px;
  }
}
@screen sm {
  .text-xbig {
    font-size: 100px;
    line-height: 100px;
  }
}
@screen xs {
  .text-xbig {
    font-size: 55px;
    line-height: 55px;
  }
}

.grid {
  list-style: none;
  /* gutter size */
  /* grid 6 column */
  /* grid 5 column */
  /* grid 4 column */
  /* grid 3 column */
  /* grid 2 column */
  /* grid 1 column */
}
.grid.grid-container {
  display: inherit;
  transition: var(--default-transition);
}
.grid li > .appear {
  opacity: 1 !important;
}
.grid li > .appear > [class*=portfolio-] {
  opacity: 1 !important;
}
.grid.gutter-very-small li {
  padding: 3px 3px;
}
.grid.gutter-small li {
  padding: 5px 5px;
}
.grid.gutter-medium li {
  padding: 7px 7px;
}
.grid.gutter-large li {
  padding: 10px 10px;
}
.grid.gutter-extra-large li {
  padding: 15px;
}
.grid.gutter-double-extra-large li {
  padding: 20px;
}
.grid.gutter-triple-extra-large li {
  padding: 30px;
}
.grid.grid-6col li {
  width: 16.67%;
  list-style: none;
}
.grid.grid-6col li.grid-item-double {
  width: 33.33%;
}
.grid.grid-5col li {
  width: 20%;
  list-style: none;
}
.grid.grid-5col li.grid-item-double {
  width: 40%;
}
.grid.grid-4col li {
  width: 25%;
  list-style: none;
}
.grid.grid-4col li.grid-item-double {
  width: 50%;
}
.grid.grid-3col li {
  width: 33.33%;
  list-style: none;
}
.grid.grid-3col li.grid-item-double {
  width: 66.67%;
}
.grid.grid-2col li {
  width: 50%;
  list-style: none;
}
.grid.grid-2col li.grid-item-double {
  width: 100%;
}
.grid.grid-1col li {
  width: 100%;
  list-style: none;
}
.grid .grid-sizer {
  padding: 0 !important;
  margin: 0 !important;
}

@screen xl {
  .grid.xl-grid-6col li {
    width: 16.67%;
  }
  .grid.xl-grid-6col li.grid-item-double {
    width: 33.33%;
  }
  .grid.xl-grid-5col li {
    width: 20%;
  }
  .grid.xl-grid-5col li.grid-item-double {
    width: 40%;
  }
  .grid.xl-grid-4col li {
    width: 25%;
  }
  .grid.xl-grid-4col li.grid-item-double {
    width: 50%;
  }
  .grid.xl-grid-3col li {
    width: 33.33%;
  }
  .grid.xl-grid-3col li.grid-item-double {
    width: 66.67%;
  }
  .grid.xl-grid-2col li {
    width: 50%;
  }
  .grid.xl-grid-2col li.grid-item-double {
    width: 100%;
  }
  .grid.xl-grid-1col li {
    width: 100%;
  }
}
@screen lg {
  .grid.lg-grid-6col li {
    width: 16.67%;
  }
  .grid.lg-grid-6col li.grid-item-double {
    width: 33.33%;
  }
  .grid.lg-grid-5col li {
    width: 20%;
  }
  .grid.lg-grid-5col li.grid-item-double {
    width: 40%;
  }
  .grid.lg-grid-4col li {
    width: 25%;
  }
  .grid.lg-grid-4col li.grid-item-double {
    width: 50%;
  }
  .grid.lg-grid-3col li {
    width: 33.33%;
  }
  .grid.lg-grid-3col li.grid-item-double {
    width: 66.67%;
  }
  .grid.lg-grid-2col li {
    width: 50%;
  }
  .grid.lg-grid-2col li.grid-item-double {
    width: 100%;
  }
  .grid.lg-grid-1col li {
    width: 100%;
  }
}
@screen md {
  .grid.md-grid-6col li {
    width: 16.67%;
  }
  .grid.md-grid-6col li.grid-item-double {
    width: 33.33%;
  }
  .grid.md-grid-5col li {
    width: 20%;
  }
  .grid.md-grid-5col li.grid-item-double {
    width: 40%;
  }
  .grid.md-grid-4col li {
    width: 25%;
  }
  .grid.md-grid-4col li.grid-item-double {
    width: 50%;
  }
  .grid.md-grid-3col li {
    width: 33.33%;
  }
  .grid.md-grid-3col li.grid-item-double {
    width: 66.67%;
  }
  .grid.md-grid-2col li {
    width: 50%;
  }
  .grid.md-grid-2col li.grid-item-double {
    width: 100%;
  }
  .grid.md-grid-1col li {
    width: 100%;
  }
}
@screen sm {
  .grid.sm-grid-6col li {
    width: 16.67%;
  }
  .grid.sm-grid-6col li.grid-item-double {
    width: 33.33%;
  }
  .grid.sm-grid-5col li {
    width: 20%;
  }
  .grid.sm-grid-5col li.grid-item-double {
    width: 40%;
  }
  .grid.sm-grid-4col li {
    width: 25%;
  }
  .grid.sm-grid-4col li.grid-item-double {
    width: 50%;
  }
  .grid.sm-grid-3col li {
    width: 33.33%;
  }
  .grid.sm-grid-3col li.grid-item-double {
    width: 66.67%;
  }
  .grid.sm-grid-2col li {
    width: 50%;
  }
  .grid.sm-grid-2col li.grid-item-double {
    width: 100%;
  }
  .grid.sm-grid-1col li {
    width: 100%;
  }
  .grid.gutter-small li {
    padding: 7px 7px;
  }
  .grid.gutter-double-extra-large li {
    padding: 15px;
  }
  .grid.gutter-medium li {
    padding: 7px 7px;
  }
  .grid.gutter-large li {
    padding: 10px 10px;
  }
}
@screen xs {
  .grid.xs-grid-6col li {
    width: 16.67%;
  }
  .grid.xs-grid-6col li.grid-item-double {
    width: 33.33%;
  }
  .grid.xs-grid-5col li {
    width: 20%;
  }
  .grid.xs-grid-5col li.grid-item-double {
    width: 40%;
  }
  .grid.xs-grid-4col li {
    width: 25%;
  }
  .grid.xs-grid-4col li.grid-item-double {
    width: 50%;
  }
  .grid.xs-grid-3col li {
    width: 33.33%;
  }
  .grid.xs-grid-3col li.grid-item-double {
    width: 66.67%;
  }
  .grid.xs-grid-2col li {
    width: 50%;
  }
  .grid.xs-grid-2col li.grid-item-double {
    width: 100%;
  }
  .grid.xs-grid-1col li {
    width: 100%;
  }
  .grid.xs-grid-6col li {
    width: 16.67%;
  }
  .grid.xs-grid-6col li.grid-item-double {
    width: 33.33%;
  }
  .grid.xs-grid-5col li {
    width: 20%;
  }
  .grid.xs-grid-5col li.grid-item-double {
    width: 40%;
  }
  .grid.xs-grid-4col li {
    width: 25%;
  }
  .grid.xs-grid-4col li.grid-item-double {
    width: 50%;
  }
  .grid.xs-grid-3col li {
    width: 33.33%;
  }
  .grid.xs-grid-3col li.grid-item-double {
    width: 66.67%;
  }
  .grid.xs-grid-2col li {
    width: 50%;
  }
  .grid.xs-grid-2col li.grid-item-double {
    width: 100%;
  }
  .grid.xs-grid-1col li {
    width: 100%;
  }
  .grid.gutter-medium li, .grid.gutter-large li {
    padding: 7px 15px;
  }
}
/* Loading */
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.grid.loading {
  @apply relative z-[1];
  transition: var(--default-transition);
}
.grid.loading::after {
  @apply bg-none absolute top-0 w-[40px] h-[40px] opacity-100 right-[inherit] z-10 text-center;
  content: "";
  left: calc(50% - 10px);
  border: 2px solid rgba(23, 23, 23, 0.2);
  border-top-color: rgba(23, 23, 23, 0.7);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
  transition: var(--default-transition);
}
.grid.loading.dark:before {
  background-color: rgba(0, 0, 0, 0.8);
}
.grid.loading.dark:after {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-top-color: rgba(255, 255, 255, 0.7);
}

.countdown-style-01 .number {
  text-align: center;
  font-size: 47px;
  line-height: 50px;
}
@screen xl {
  .countdown-style-01 .number {
    font-size: 40px;
    line-height: 50px;
  }
}
.countdown-style-01 .unit {
  @apply font-serif uppercase;
  margin: -2px 0px 0px;
  font-size: 12px;
  opacity: 0.5;
  line-height: 30px;
  font-weight: 400;
}
.countdown-style-01 > div {
  text-align: center;
}
@screen xs {
  .countdown-style-01 > div {
    @apply w-[50%] mb-[30px] px-[0];
  }
}
.countdown-style-01 > div:first-child {
  padding-left: 0;
}
.countdown-style-01 > div:last-child {
  padding-right: 0;
}
.countdown-style-01 span {
  display: block;
  line-height: normal;
}

.countdown-style-02 .unit {
  @apply font-serif font-normal block text-xs leading-[30px] uppercase text-center;
  opacity: 0.6;
}
.countdown-style-02 > div {
  @apply m-0 relative text-center inline-block w-[130px] font-light sm:w-[120px];
}
@screen xs {
  .countdown-style-02 > div {
    @apply w-[50%] mb-[25px] px-[15px];
  }
}
.countdown-style-02 > div:nth-child(2)::after {
  @apply xs:hidden;
}
.countdown-style-02 > div:after {
  content: ":";
  position: absolute;
  right: 0;
  top: 5px;
  font-size: 34px;
  opacity: 0.45;
}
.countdown-style-02 > div:last-child:after {
  content: "";
}
.countdown-style-02 .number {
  display: inline-block;
  width: 90px;
  font-size: 47px;
  text-align: center;
  line-height: 50px;
  position: relative;
}

.countdown-style-03 > div {
  display: inline-block;
  text-align: center;
  float: none;
  margin: 0;
  padding: 0 20px;
  position: relative;
}
@screen xs {
  .countdown-style-03 > div {
    @apply w-[50%] mb-[15px] px-[15px];
  }
}
.countdown-style-03 .number {
  font-size: 38px;
  letter-spacing: -1px;
  text-align: center;
  line-height: 38px;
}
.countdown-style-03 .unit {
  @apply font-serif text-sm block text-center font-medium uppercase;
  margin-top: -2px;
  opacity: 1;
}

.custom-icon-with-text01 {
  transition: var(--default-transition);
  @apply px-[2rem] py-[45px] md:px-[60px] md:py-[45px];
}
.custom-icon-with-text01 .feature-box-icon {
  position: relative;
  margin-bottom: 20px;
}
.custom-icon-with-text01 .feature-box-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 40px;
  background-image: linear-gradient(to right, #556fff, #556fff, #e05fc4, #f767a6, #ff798e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.custom-icon-with-text01 .feature-box-content {
  @apply text-center;
}
.custom-icon-with-text01 .feature-box-content h6 {
  @apply font-semibold mb-[5px] tracking-[0px];
}
.custom-icon-with-text01 .feature-box-content p {
  margin-bottom: 0;
}
.custom-icon-with-text01 .feature-box-icon {
  @apply flex justify-center;
  margin-bottom: 30px;
}
.custom-icon-with-text01:hover {
  box-shadow: 5px 5px 20px rgb(231, 228, 228);
  border-color: transparent;
  transform: translate3d(0, -7px, 0);
  opacity: 1;
  background-color: white;
  border-radius: 6px;
}

.custom-icon-with-text02 {
  @apply p-[63px] lg:p-[36px] xs:p-[33px];
}
.custom-icon-with-text02 .feature-box-icon {
  position: relative;
  margin-bottom: 20px;
}
.custom-icon-with-text02 .feature-box-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  height: 40px;
  width: 40px;
}
.custom-icon-with-text02 .feature-box-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 40px;
  background-image: linear-gradient(to right, #556fff, #556fff, #e05fc4, #f767a6, #ff798e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.custom-icon-with-text02 .feature-box-icon-shap {
  display: inline-block;
}
.custom-icon-with-text02 .drop-shadow svg {
  display: inline-block;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.1));
}
.custom-icon-with-text02 .feature-box-content .title {
  letter-spacing: 0px;
}

.dropcaps-style01 span {
  @apply text-darkgray font-semibold text-center;
  float: left;
  font-size: 50px;
  line-height: normal;
  margin: 0 20px 0 0;
  padding: 10px 0;
}
@screen xs {
  .dropcaps-style01 span {
    padding: 10px 10px;
  }
}

.dropcaps-style02 span {
  @apply font-medium bg-darkgray text-center;
  float: left;
  font-size: 30px;
  height: 55px;
  line-height: 25px;
  padding: 15px 0;
  width: 55px;
  margin-top: 5px;
  margin: 0 20px 0 0;
  color: #fff;
}

.dropcaps-style03 span {
  @apply rounded-md font-medium text-center text-darkgray;
  float: left;
  border: 1px solid;
  border-color: #e4e4e4;
  font-size: 30px;
  height: 55px;
  line-height: 25px;
  padding: 15px;
  width: 55px;
  margin-top: 5px;
  margin: 0 20px 0 0;
}

.dropcaps-style04 span {
  @apply relative font-semibold text-center;
  float: left;
  font-size: 100px;
  line-height: 100px;
  margin: 0 35px 0 0;
  padding: 0 8px;
}
.dropcaps-style04 span::before {
  @apply absolute block;
  border-bottom: 1px solid;
  content: "";
  width: 100%;
  top: 55%;
  left: 0;
}

.dropcaps-style05 span {
  @apply text-darkgray text-center font-semibold;
  float: left;
  border-radius: 50%;
  font-size: 35px;
  padding: 15px;
  width: 65px;
  height: 65px;
  line-height: 35px;
  border: 1px solid;
  border-color: #e4e4e4;
  font-size: 35px;
  margin: 0 20px 0 0;
}

.dropcaps-style06 span {
  @apply bg-darkgray text-white text-center font-semibold;
  float: left;
  border-radius: 50%;
  font-size: 35px;
  padding: 15px;
  width: 65px;
  height: 65px;
  line-height: 35px;
  margin: 0 20px 0 0;
}

.my-atropos .title {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.my-atropos .atropos-shadow {
  display: none;
}
.my-atropos .atropos-rotate-touch {
  text-align: center;
  overflow: hidden;
}
.my-atropos .atropos-inner {
  text-align: center;
  overflow: visible;
}

.ril__caption {
  background: none !important;
  display: inline-block !important;
  text-align: center !important;
}

.image-gallery-01 {
  display: flex;
  flex-wrap: wrap;
}
.image-gallery-01 li {
  padding: 15px 15px;
  width: 25%;
}
@media (max-width: 991px) {
  .image-gallery-01 li {
    width: 50%;
  }
}
@media (max-width: 575px) {
  .image-gallery-01 li {
    width: 100%;
  }
}
.image-gallery-01 .image-box {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--default-transition);
}
.image-gallery-01 .image-box img {
  height: 100%;
  width: 100%;
  transition: var(--default-transition);
  opacity: 1;
}
.image-gallery-01 .image-box:hover img {
  transform: scale(1.1, 1.1);
  opacity: 0.15;
}
.image-gallery-01 .image-box:hover .image-box-wrapper {
  opacity: 1;
}
.image-gallery-01 .image-box:hover .image-box-wrapper i {
  opacity: 1;
  transform: translateY(0px);
}
.image-gallery-01 .image-box .image-box-wrapper i {
  opacity: 0;
  transform: translateY(-15px);
  transition: 0.3s ease-in-out;
}
.image-gallery-01:hover .image-box {
  transition: var(--default-transition);
}
.image-gallery-01 .image-box-wrapper {
  transition: var(--default-transition);
  opacity: 0;
}

.image-gallery-02 .image-box-wrapper {
  transition: var(--default-transition);
  opacity: 0;
}
.image-gallery-02 .image-box {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--default-transition);
}
.image-gallery-02 .image-box:hover {
  transition: var(--default-transition);
  transform: translateY(-5px);
}
.image-gallery-02 .image-box img {
  height: 100%;
  width: 100%;
  transition: var(--default-transition);
  opacity: 1;
}
.image-gallery-02 .image-box:hover img {
  opacity: 0.15;
}
.image-gallery-02 .image-box:hover .image-box-wrapper {
  opacity: 1;
}
.image-gallery-02 .image-box:hover .image-box-wrapper i {
  opacity: 1;
  transform: translateY(0px);
}
.image-gallery-02 .image-box-wrapper i {
  opacity: 0;
  transform: translateY(-15px);
  transition: 0.3s ease-in-out;
}

.image-gallery-03 {
  column-count: 4;
  column-gap: 0;
}
@media (max-width: 991px) {
  .image-gallery-03 {
    column-count: 2;
  }
}
@media (max-width: 575px) {
  .image-gallery-03 {
    column-count: 1;
  }
}
.image-gallery-03 li {
  display: inline-block;
  padding: 15px;
}
.image-gallery-03 .image-box {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--default-transition);
}
.image-gallery-03 .image-box img {
  transition: var(--default-transition);
  opacity: 1;
  height: 100%;
  width: 100%;
}
.image-gallery-03 .image-box:hover img {
  opacity: 0.15;
}
.image-gallery-03 .image-box:hover .image-box-wrapper {
  opacity: 1;
}
.image-gallery-03 .image-box:hover .image-box-wrapper i {
  opacity: 1;
  transform: translateY(0px);
}
.image-gallery-03 .image-box .image-box-wrapper i {
  opacity: 0;
  transform: translateY(-15px);
  transition: 0.3s ease-in-out;
}
.image-gallery-03:hover .image-box {
  transition: var(--default-transition);
}
.image-gallery-03 .image-box-wrapper {
  transition: var(--default-transition);
  opacity: 0;
}

.info-banner-style01 {
  transition: var(--default-transition);
  @apply px-[2rem] py-[55px] lg:py-[55px] lg:px-4;
}
.info-banner-style01 .feature-box-icon {
  position: relative;
  margin-bottom: 20px;
}
.info-banner-style01 .feature-box-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 40px;
  background-image: linear-gradient(to right, #556fff, #556fff, #e05fc4, #f767a6, #ff798e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.info-banner-style01 .title {
  @apply text-darkgray;
}
.info-banner-style01 .feature-box-content {
  @apply text-center;
}
.info-banner-style01 .feature-box-content h6 {
  @apply font-medium  xs:mb-[5px];
}
.info-banner-style01 .feature-box-content p {
  margin-bottom: 0;
}
.info-banner-style01 .feature-box-icon {
  @apply flex justify-center;
  margin-bottom: 30px;
}
.info-banner-style01:hover {
  box-shadow: 5px 5px 20px rgb(231, 228, 228);
  border-color: transparent;
  transform: translate3d(0, -7px, 0);
  opacity: 1;
  background-color: white;
  border-radius: 6px;
}

.list-style-01 {
  color: #232323;
}
.list-style-01 li {
  position: relative;
  padding: 12px 25px 14px 25px;
  z-index: 1;
  border-radius: 4px;
  list-style: none;
  transition-duration: 0.4s;
}
.list-style-01 li:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  background: white;
  padding: 12px 25px 14px 50px;
}
.list-style-01 li:hover i {
  left: 25px;
  opacity: 1;
}
.list-style-01 li i {
  position: absolute;
  top: 21px;
  left: 0;
  font-size: 14px;
  opacity: 0.7;
  color: #757575;
  transition-duration: 0.4s;
}

.list-style-02 li {
  @apply flex;
  margin-bottom: 20px;
}
.list-style-02 li:last-child {
  margin-bottom: 0;
}
.list-style-02 i {
  @apply relative leading-[30px] mr-[10px] text-[18px];
  color: var(--base-color, #0038e3);
}

.list-style-03 {
  @apply font-medium font-serif uppercase text-darkgray text-sm;
}
.list-style-03 li {
  @apply text-darkgray p-[15px] pl-0 leading-[30px] border-b border-mediumgray items-center flex;
}
.list-style-03 li:last-child {
  border: 0px;
}
.list-style-03 li:first-child {
  @apply pt-0;
}
.list-style-03 i {
  @apply leading-[30px] mr-[10px] text-[18px];
  color: var(--base-color, #0038e3);
}

.list-style-04 li {
  padding: 10px 0;
  border-bottom: 1px solid #e4e4e4;
}
.list-style-04 li:last-child {
  border-bottom: 0;
}

.list-style-05 li {
  @apply flex items-center py-[10px] px-[20px] mb-[20px] rounded-[6px];
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.list-style-05 li:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.list-style-05 i {
  color: var(--base-color, #0038e3);
  margin-right: 15px;
}
.list-style-05 span {
  @apply text-darkgray;
}

.list-style-06 {
  @apply text-left pl-[20px] ml-[10px];
}
.list-style-06 li {
  list-style: disc;
  margin-bottom: 10px;
}

.list-style-07 li {
  @apply flex items-start rounded-md mb-[35px];
}
.list-style-07 i {
  @apply text-white text-center h-[25px] w-[25px] mr-[25px] mt-[5px] rounded-[50%] pt-[4px];
  flex: 0 0 auto;
  background-color: var(--base-color, #0038e3);
}
.list-style-07 i::before {
  font-size: 10px;
}
.list-style-07 .list-title {
  @apply block text-xmd font-medium mb-[5px] text-[#2f2f2f];
}
.list-style-07 .list-content {
  @apply block w-[80%] xs:w-full mb-0;
}

.list-style-08 li {
  @apply relative inline-block w-full mb-[15px] leading-[18px];
}
.list-style-08 a {
  @apply inline-block text-left;
  text-decoration: none;
  color: #828282;
  transition: var(--default-transition);
}
.list-style-08 a:hover {
  color: var(--base-color, #0038e3);
}
.list-style-08 .list-number {
  @apply text-[14px] absolute text-right top-[1px] right-0;
}

.overline-icon-box {
  border-color: var(--base-color, #0038E3);
  text-align: center;
  z-index: 1;
  position: relative;
  padding: 63px 56px;
  border-top-width: 4px;
  border-top-style: solid;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 25px 0px;
  transition: var(--default-transition);
  transition-duration: 0.4s;
  @apply md:p-[36px] sm:p-[55px];
}
.overline-icon-box:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  transform: translate3d(0, -3px, 0);
}
.overline-icon-box .feature-box-icon i {
  color: var(--base-color, #0038e3);
  display: block;
  font-size: 50px;
  @apply mb-[35px] md:mb-[15px];
}
.overline-icon-box .feature-box-content span {
  @apply font-serif text-xmd font-medium text-darkgray md:w-[98%];
  margin-bottom: 10px;
  display: block;
}

.filter-menu {
  @apply sm:flex sm:flex-col;
}
.filter-menu .active {
  @apply text-black border-transparent;
}
.filter-menu .active span {
  @apply border-black border-b border-solid;
}
.filter-menu li {
  cursor: pointer;
  @apply sm:mb-[10px];
}
.filter-menu li:first-child {
  padding-left: 0;
}
.filter-menu li:last-child {
  padding-right: 0;
}
@screen sm {
  .filter-menu li:first-child {
    padding-left: 20px;
  }
  .filter-menu li:last-child {
    padding-right: 20px;
  }
}
.filter-menu li span {
  @apply border-b border-solid border-transparent;
  transition: var(--default-transition);
  @apply font-serif font-medium;
}
.filter-menu li span:hover {
  @apply text-black border-black;
}

.portfolio-classic {
  @apply p-0 rounded-[6px] overflow-hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.portfolio-classic:hover {
  transform: translateY(-5px);
}
.portfolio-classic:hover .portfolio-image img {
  transform: scale(1, 1);
  opacity: 0.15;
}
.portfolio-classic:hover .portfolio-image .portfolio-icon {
  opacity: 1;
}
.portfolio-classic:hover .portfolio-image .portfolio-icon a,
.portfolio-classic:hover .portfolio-image .portfolio-icon > div {
  opacity: 1;
  transition-delay: 0.1s;
  transform: translateY(0);
}
.portfolio-classic:hover .portfolio-image .portfolio-icon a:hover,
.portfolio-classic:hover .portfolio-image .portfolio-icon > div:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
}
.portfolio-classic .portfolio-image {
  @apply relative overflow-hidden;
  transition: var(--default-transition);
  background: var(--overlay-color, #000);
}
.portfolio-classic .portfolio-image img {
  @apply w-full;
  transition: var(--default-transition);
}
.portfolio-classic .portfolio-image .portfolio-icon {
  @apply inline-block absolute align-middle top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center opacity-0;
}
.portfolio-classic .portfolio-image .portfolio-icon a,
.portfolio-classic .portfolio-image .portfolio-icon > div {
  @apply w-[40px] h-[40px] inline-block align-middle leading-[40px] text-center my-0 mx-[5px] relative z-[3] top-1/2 bg-[#fff] text-[#262b35] rounded-[50%] cursor-pointer;
  opacity: 0;
  transform: translateY(15px);
  transition: var(--default-transition);
}
.portfolio-classic .portfolio-image .portfolio-icon a:last-child,
.portfolio-classic .portfolio-image .portfolio-icon > div:last-child {
  transition-delay: 0.2s;
}
.portfolio-classic .portfolio-image .portfolio-icon a i,
.portfolio-classic .portfolio-image .portfolio-icon > div i {
  @apply text-sm;
}
.portfolio-classic .portfolio-caption {
  @apply py-[30px] bg-[#fff] text-center lg:py-[20px];
}
.portfolio-classic .portfolio-caption a span {
  @apply font-medium text-darkgray font-serif;
}

.portfolio-box-boxed {
  @apply relative overflow-hidden;
}
.portfolio-box-boxed img {
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.portfolio-box-boxed:hover img {
  transform: scale(1.1);
}
.portfolio-box-boxed .portfolio-hover {
  @apply absolute left-0 right-0 text-center font-serif z-[999] my-0 mx-auto w-[90%] bottom-0 bg-[#fff] text-[#000] p-[15px] rounded-[3px];
}
.portfolio-box-boxed:hover .portfolio-hover {
  @apply my-[15px] mx-auto;
  transform: translateY(0);
}

.portfolio-boxed .portfolio-image img {
  transition: var(--default-transition);
}
.portfolio-boxed .portfolio-image .porfolio-hover {
  @apply left-[20px] bottom-[20px] h-auto;
  opacity: 0;
  width: calc(100% - 40px);
  top: inherit;
  transform: translateY(100%);
  transition: var(--default-transition);
}
.portfolio-boxed .portfolio-image .porfolio-hover i {
  transition: var(--default-transition);
}
.portfolio-boxed .portfolio-image .porfolio-hover:hover i {
  transform: translateX(5px);
}
.portfolio-boxed:hover .portfolio-image img {
  transform: scale(1.1);
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.portfolio-boxed:hover .porfolio-hover {
  opacity: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
}

.portfolio-colorful .portfolio-box {
  @apply relative;
}
.portfolio-colorful .portfolio-box:hover .portfolio-hover {
  @apply opacity-100;
}
.portfolio-colorful .portfolio-box:hover .portfolio-hover i {
  @apply opacity-100 translate-x-0;
}
.portfolio-colorful .portfolio-box:hover .portfolio-hover .portfolio-content span,
.portfolio-colorful .portfolio-box:hover .portfolio-hover .portfolio-content h6 {
  transform: translateY(0);
}
.portfolio-colorful .portfolio-box img {
  @apply inline-block w-full h-auto;
}
.portfolio-colorful .portfolio-box .portfolio-hover {
  @apply absolute w-full h-full left-0 top-0 opacity-0;
  transition: var(--default-transition);
  background: var(--brand-color);
}
.portfolio-colorful .portfolio-box .portfolio-hover i {
  @apply absolute opacity-0 top-12 right-16 text-[28px] text-white;
  transition: var(--default-transition);
  transform: translateX(15px);
}
.portfolio-colorful .portfolio-box .portfolio-hover .portfolio-content {
  @apply absolute overflow-hidden text-left bottom-12 left-16;
  transition: var(--default-transition);
}
.portfolio-colorful .portfolio-box .portfolio-hover .portfolio-content span {
  transform: translateY(-100px);
  transition: var(--default-transition);
}
.portfolio-colorful .portfolio-box .portfolio-hover .portfolio-content h6 {
  @apply font-semibold text-white font-serif text-[1.8rem] leading-[2.5rem];
  transform: translateY(100px);
  transition: var(--default-transition);
  transition-duration: 0.5s;
}

.portfolio-bordered {
  @apply overflow-hidden;
}
.portfolio-bordered .portfolio-image {
  @apply relative;
  background: var(--overlay-color);
}
.portfolio-bordered .portfolio-image img {
  transition: var(--default-transition);
}
.portfolio-bordered .portfolio-image .portfolio-border-hover {
  @apply flex justify-center items-center bg-white absolute w-full h-full left-0 top-0 opacity-0;
  transition: var(--default-transition);
}
.portfolio-bordered .portfolio-image .portfolio-border-hover .scale {
  opacity: 0;
  transform: scale3d(1, 1, 1);
  transition: var(--default-transition);
}
.portfolio-bordered .portfolio-image:hover img {
  opacity: 0.5;
  filter: blur(5px);
  transform: scale(1.1, 1.1);
}
.portfolio-bordered .portfolio-image:hover .portfolio-border-hover {
  @apply left-[20px] top-[20px];
  opacity: 1;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  transform: scale3d(1, 1, 1);
}
.portfolio-bordered .portfolio-image:hover .portfolio-border-hover .scale {
  opacity: 1;
}

.portfolio-overlay .portfolio-image {
  background: var(--overlay-color, #000);
}
.portfolio-overlay .portfolio-image img {
  transition: var(--default-transition);
}
.portfolio-overlay .portfolio-hover {
  @apply top-0 left-0 w-full h-full opacity-0;
  transition: var(--default-transition);
}
.portfolio-overlay .portfolio-hover i {
  @apply absolute top-2/4 left-2/4;
  transform: translate(-50%, calc(-50% - 15px));
  transition: var(--default-transition);
}
.portfolio-overlay:hover .portfolio-image img {
  transform: scale(1.1);
  opacity: 0.15;
}
.portfolio-overlay:hover .portfolio-hover {
  opacity: 1;
}
.portfolio-overlay:hover .portfolio-hover i {
  transform: translate(-50%, -50%);
}

.portfolio-switchimg {
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.portfolio-switchimg .portfolio-image {
  @apply overflow-hidden relative rounded-[4px];
  transition: var(--default-transition);
}
.portfolio-switchimg .portfolio-image img {
  transition: var(--default-transition);
}
.portfolio-switchimg .portfolio-image .portfolio-switch-image {
  @apply absolute top-0 left-0 w-full h-full;
  transition: var(--default-transition);
  opacity: 0;
  transform: scale3d(1.1, 1.1, 1.1) translate3d(0, 0, 0);
}
.portfolio-switchimg .portfolio-content {
  transition: var(--default-transition);
}
.portfolio-switchimg:hover {
  transform: translateY(-5px);
}
.portfolio-switchimg:hover .portfolio-switch-image {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.portfolio-switchimg:hover .portfolio-content {
  transform: translateY(-10px);
}

@screen lg {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(4n+0) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(4n+1) .portfolio-box {
    padding: 15% 0;
  }
}
@screen lg {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(4n+2) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(4n+3) .portfolio-box {
    padding: 0 15%;
  }
}
.portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+0) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+1) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+3) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+6) .portfolio-box {
  padding: 25% 0;
}
@screen lg {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+0) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+1) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+3) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+6) .portfolio-box {
    padding: 15% 0;
  }
}
.portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+2) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+4) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+5) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+7) .portfolio-box {
  padding: 0 15%;
}
@screen lg {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+2) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+4) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+5) .portfolio-box, .portfolio-scattered.row-cols-xl-4 .col:nth-child(8n+7) .portfolio-box {
    padding: 0 15% 15%;
  }
}
@screen sm {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(odd) .portfolio-box {
    padding: 0 0 10%;
  }
}
@screen sm {
  .portfolio-scattered.row-cols-xl-4 .col:nth-child(even) .portfolio-box {
    padding: 0 10% 10%;
  }
}
.portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+0) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+1) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+3) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+5) .portfolio-box {
  padding: 15% 0;
}
.portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+2) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+4) .portfolio-box, .portfolio-scattered.row-cols-lg-3 .col:nth-child(6n+6) .portfolio-box {
  padding: 0 15%;
}
.portfolio-scattered.row-cols-lg-2:nth-child(4n+0) .portfolio-box, .portfolio-scattered.row-cols-lg-2:nth-child(4n+1) .portfolio-box {
  padding: 15% 0;
}
.portfolio-scattered.row-cols-lg-2:nth-child(4n+2) .portfolio-box, .portfolio-scattered.row-cols-lg-2:nth-child(4n+3) .portfolio-box {
  padding: 0 15%;
}
.portfolio-scattered > .col:nth-child(8n+1) .portfolio-box {
  padding: 25% 0;
}
.portfolio-scattered > .col:nth-child(8n+2) .portfolio-box {
  padding: 0 15%;
}
.portfolio-scattered .portfolio-box .portfolio-image {
  clip-path: inset(0 0 0 0);
  transition: var(--default-transition);
}
.portfolio-scattered .portfolio-box:hover .portfolio-image {
  clip-path: inset(5% 5% 5% 5%);
}
.portfolio-scattered .portfolio-hover {
  transition: var(--default-transition);
}

.portfolio-swiper-slider {
  padding-bottom: 60px;
}
.portfolio-swiper-slider .swiper-button-prev,
.portfolio-swiper-slider .swiper-button-next {
  --swiper-navigation-background: transparent;
  transition: var(--default-transition);
  @apply text-darkgray text-xmd leading-[40px] h-[40px] w-[40px] mr-[5px] rounded-full border top-auto bottom-[10px] -translate-x-1/2;
}
.portfolio-swiper-slider .swiper-button-prev:hover,
.portfolio-swiper-slider .swiper-button-next:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-color: #fff;
  background: #fff;
}
.portfolio-swiper-slider .swiper-button-prev {
  left: calc(50% - 30px);
}
.portfolio-swiper-slider .swiper-button-next {
  left: calc(50% + 30px);
}

.portfolio-slider {
  transition: var(--default-transition);
  cursor: pointer;
}
.portfolio-slider .portfolio-image {
  transition: var(--default-transition);
}
.portfolio-slider .portfolio-hover {
  transition: var(--default-transition);
  transform: translateY(15px);
  opacity: 0;
}
.portfolio-slider:hover {
  transform: translateY(-5px);
}
.portfolio-slider:hover .portfolio-image {
  background-image: linear-gradient(to right top, #0039e3, #4132e0, #5e28dd, #741bd9, #8600d4);
  transform: scale(1, 1);
}
.portfolio-slider:hover .portfolio-image img {
  opacity: 0.15;
}
.portfolio-slider:hover .portfolio-hover {
  opacity: 1;
  transition-delay: 0.2s;
  transform: translateY(0);
}

.portfolio-justified {
  overflow: hidden;
  position: static;
}
.portfolio-justified .portfolio-caption {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.portfolio-justified:hover .portfolio-caption {
  opacity: 1;
  visibility: visible;
}

.text-slider-01 div[class*=col] {
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  background-position: center;
  padding: 0;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
}
.text-slider-01 .swiper-wrapper .text-slider-swiper-wrapper {
  opacity: 0;
}
.text-slider-01 .swiper-slide {
  opacity: 0.3;
  transition: var(--default-transition);
}
.text-slider-01 .swiper-slide.swiper-slide-prev, .text-slider-01 .swiper-slide.swiper-slide-next {
  opacity: 0.3;
}
.text-slider-01 .swiper-slide.swiper-slide-active {
  opacity: 1;
}
.text-slider-01 .swiper-slide .slider-content {
  opacity: 0;
}
.text-slider-01 .swiper-slide.swiper-slide-active .text-slider-swiper-wrapper {
  opacity: 1;
}
.text-slider-01 .swiper-slide.swiper-slide-active .slider-content {
  opacity: 1;
}
.text-slider-01 .swiper-button-prev,
.text-slider-01 .swiper-button-next {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 40px;
  margin: 0;
  outline: 0;
  transition: var(--default-transition);
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  background-color: rgba(23, 23, 23, 0.5);
  color: #fff;
}
.text-slider-01 .swiper-button-prev:after,
.text-slider-01 .swiper-button-next:after {
  @apply text-base;
}
.text-slider-01 .swiper-button-prev:hover,
.text-slider-01 .swiper-button-next:hover {
  background-color: #fff;
  @apply text-darkgray;
}
.text-slider-01 .swiper-button-prev:after {
  content: "\e910";
  font-family: "feather";
}
.text-slider-01 .swiper-button-next {
  right: 40px;
  left: auto;
}
.text-slider-01 .swiper-button-next:after {
  content: "\e912";
  font-family: "feather";
}

.text-slider-02 {
  position: relative;
  transition: var(--default-transition);
}
.text-slider-02 .swiper-wrapper {
  cursor: url("../img/icon-move-black.png"), move;
}
.text-slider-02 .swiper-slide .overlay {
  opacity: 0.6;
}
.text-slider-02 .swiper-slide .slider-content {
  opacity: 0;
}
.text-slider-02 .swiper-slide .slider-content a {
  transition: var(--default-transition);
}
.text-slider-02 .swiper-slide .text-wrapper {
  opacity: 0;
}
.text-slider-02 .swiper-slide.swiper-slide-active .overlay {
  opacity: 0.2;
}
.text-slider-02 .swiper-slide.swiper-slide-active .slider-content {
  opacity: 1;
}
.text-slider-02 .swiper-slide.swiper-slide-active .text-wrapper {
  opacity: 1;
}

/*# sourceMappingURL=global.css.map */
